// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

import "prismjs/themes/prism.css"
import React from 'react'

export const onClientEntry = () => {
    if (process.env.NODE_ENV !== 'production') {
      const whyDidYouRender = require('@welldone-software/why-did-you-render')
      whyDidYouRender(React, {
        trackAllPureComponents: true
      })
    }
  }
